import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

const LinkMap = {
  Jasmin: "2c17a362f5d5",
  Mocha: "2c17a362f5d5",
  Jest: "2c17a362f5d5",
  JSDOM: "2c17a362f5d5",
  "React-Test-Renderer": "2c17a362f5d5",
  Enzyme: "2c17a362f5d5",
  Cheerio: "2c17a362f5d5",
  Storybook: "2c17a362f5d5",
  "React Cosmo": "2c17a362f5d5",
  "React Styleguidist": "2c17a362f5d5",
  Bit: "2c17a362f5d5",
  Selenium: "2c17a362f5d5",
  PhantomJS: "2c17a362f5d5",
  CasperJS: "2c17a362f5d5",
  Puppeteer: "2c17a362f5d5",
  Prettier: "bf7c7adde2ad",
  TypeScript: "bf7c7adde2ad",
  Testing: "bf7c7adde2ad",
  Queries: "bf7c7adde2ad",
  TDD: "30e225c29c14",
  BDD: "30e225c29c14",
  "WebApp Test": "1ce6af1bcca4",
  "Presentation Layer": "30e225c29c14",
  "Business Layer": "30e225c29c14",
  "NodeJS Mocha": "d0a53372ff9b",
  "V8 Engine": "b485799ec4c9",
  "Performance API": "cfb99a232a76",

  JMeter: "4fbea9444449",
  "Load Test": "4fbea9444449",
  "Aggregate Graph": "4fbea9444449",
  "View Result in Table": "4fbea9444449",
  "View Results Tree": "4fbea9444449",

  "E2E Testing": "7ecbfcf3197a",
  Cypress: "7ecbfcf3197a",
  "node_modules Optimization": "8cd52e6c77b5",

  "Snapshot Testing": "a19a04cb1336",
  "DOM Testing": "a19a04cb1336",
  "React Testing Library": "a19a04cb1336",

  "Playwright vs Cypress": "e81f09d0db5e",
}

const storiesGroup = [
  {
    title: "Component Testing",
    storyPart: [
      {
        title:
          "Platform Components Nasıl Test Yazılır? (Snapshot ve DOM Testing)",
        postId: "a19a04cb1336",
      },
    ],
  },
  {
    title: "E2E Testing",
    storyPart: [
      {
        title: "Cypress gibi bir E2E Test Aracı Kullanma Nedenleri",
        postId: "7ecbfcf3197a",
      },
      {
        title: "Cypress -> Playwright",
        postId: "e81f09d0db5e",
      },
    ],
  },
  {
    title: "Optimization",
    storyPart: [
      {
        title: "Node Modules Nasıl Optimize Ederiz",
        postId: "8cd52e6c77b5",
      },
    ],
  },
  {
    title: "Testing",
    storyPart: [
      {
        title: "Frontend Testing",
        postId: "2c17a362f5d5",
      },
      {
        title: "Testing Spectrum",
        postId: "bf7c7adde2ad",
      },
      {
        title: "TDD vs BDD",
        postId: "30e225c29c14",
      },
      {
        title: "Web Uygulamasına Nasıl Test Yazılır",
        postId: "1ce6af1bcca4",
      },
      {
        title: "NodeJS Unit Test ve API Testi",
        postId: "d0a53372ff9b",
      },
    ],
  },
  {
    title: "Performans",
    storyPart: [
      {
        title: "Node JS Mimarisi",
        postId: "b485799ec4c9",
      },
      {
        title: "NodeJS işlem süresini ölçme",
        postId: "cfb99a232a76",
      },
      {
        title: "JMeter Load Testi",
        postId: "4fbea9444449",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Test",
  path: "web-test-performans",
}

const WebTestPerformancePage = () => (
  <StoryGroup
    title={context.title}
    complex={true}
    stories={storiesGroup}
    linkMap={context.linkMap}
  />
)

export default WebTestPerformancePage
